import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getStockQty, updateQuantity } from "../../../../api/stock/stock";
import { DialogContentText, ListItemText } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface StockQtyAdjustProps {
  colourOptions: { id: number; description: string }[];
  sizeOptions: { id: number; description: string }[];
  stockID: number;
}

//Error handler in case something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

export default function StockQtyAdjust(props: StockQtyAdjustProps) {
  //Load in props and declare necessary hooks / states
  const { colourOptions, sizeOptions, stockID } = props;
  const [open, setOpen] = React.useState(false);
  const [chosenSizeOption, setChosenSizeOption] = React.useState<
    number | string
  >("");
  const [chosenColourOption, setChosenColourOption] = React.useState<
    number | string
  >("");
  const [notificationMessage, setNotificationMessage] = React.useState({
    type: "error",
    description: "",
  });

  const [thisVariantQty, setThisVariantQty] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  //What to do if the user decides to close dialog box
  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  //Function to execute when the user changes the size dropdown
  const changeSize = (choiceDescription: string) => {
    //Calculate the size and colour IDs based off of choices descriptions
    const sizeID =
      sizeOptions.find((element) => element.description === choiceDescription)
        ?.id ?? 0;
    const colourID =
      colourOptions.find(
        (element) => element.description === chosenColourOption
      )?.id ?? 0;
    //Update the variant stock quantity and update the chosen size option to reflect change
    getStockQuantity(sizeID, colourID);
    setChosenSizeOption(choiceDescription);
  };

  //Function as above but for colour changes
  const changeColour = (choiceDescription: string) => {
    const sizeID =
      sizeOptions.find((element) => element.description === chosenSizeOption)
        ?.id ?? 0;
    const colourID =
      colourOptions.find((element) => element.description === choiceDescription)
        ?.id ?? 0;
    getStockQuantity(sizeID, colourID);
    setChosenColourOption(choiceDescription);
  };

  //Function will get and then set quantity for variant details provided
  const getStockQuantity = async (sizeID: number, colourID: number) => {
    //Try to load the quantity
    try {
      const response = await getStockQty(stockID, sizeID, colourID);
      //Set if succesful
      setThisVariantQty(response);
      //Show an error otherwise
    } catch {
      setNotificationMessage({
        type: "error",
        description: "Something went wrong while retrieving the stock levels",
      });
    }
  };

  //Function to run when trying to change the quantity of an item in stock
  const handleChangeQuantity = async (quantity: number) => {
    //Calculate relevant IDs based on choice descriptions
    const sizeID =
      sizeOptions.find((element) => element.description === chosenSizeOption)
        ?.id ?? 0;
    const colourID =
      colourOptions.find(
        (element) => element.description === chosenColourOption
      )?.id ?? 0;
    //Try to update the quantity accordingly
    try {
      const response = await updateQuantity(
        stockID,
        sizeID,
        colourID,
        quantity
      );
      //Set locally tracked quantity if succesful
      setThisVariantQty(thisVariantQty + quantity);
      //Display an error otherwise
    } catch {
      setNotificationMessage({
        type: "error",
        description: "Something went wrong while adjusting the stock levels",
      });
    }
  };

  //Return JSX describing layout of box
  return (
    <div>
      {/* A button should be show in first instance facilitating the opening of screen*/}
      <Button onClick={handleClickOpen} color="primary" variant="contained">
        Adjust Stock Levels
      </Button>

      {/* Declares dialog box to appear with options*/}
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        {/* Sets box title*/}
        <DialogTitle>Adjust Stock Levels</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="sizeDropdownLabelID" htmlFor="demo-dialog-native">
                Size
              </InputLabel>
              {/* Declares size dropdown giving user choice between different size options for this stock item */}
              <Select
                labelId="sizeDropdownLabelID"
                value={chosenSizeOption}
                onChange={(e) => changeSize(String(e.target.value))}
                input={<OutlinedInput label="Size" id="demo-dialog-native" />}
                color="primary"
              >
                {/*Defines JSX for each dropdown option */}
                {sizeOptions.map((type) => (
                  <MenuItem key={type.id} value={type.description}>
                    <ListItemText primary={type.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-dialog-select-label">Colour</InputLabel>
              {/* Define dropdown for colour option giving user choice between different colour options */}
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={chosenColourOption}
                onChange={(e) => changeColour(String(e.target.value))}
                input={<OutlinedInput label="Colour" color="primary" />}
              >
                {/*Defines JSX for each dropdown option */}
                {colourOptions.map((type) => (
                  <MenuItem key={type.id} value={type.description}>
                    <ListItemText primary={type.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        {/* Show notification message if one exists*/}
        {notificationMessage.description !== "" ? (
          <DialogContent sx={{ padding: "8px" }}>
            <DialogContentText
              id="alert-dialog-description"
              color={notificationMessage.type === "error" ? "red" : "green"}
              sx={{
                display: "flex",
                width: "100%",
                flexFlow: "column wrap",
                alignContent: "flex-end",
              }}
            >
              {notificationMessage.description}
            </DialogContentText>
          </DialogContent>
        ) : null}

        {/* If a colour and size have been selected, show the quantity for those items*/}
        {chosenColourOption !== "" && chosenSizeOption !== "" ? (
          <DialogContent sx={{ padding: "8px" }}>
            <DialogContentText
              id="alert-dialog-description"
              color="black"
              sx={{
                display: "flex",
                width: "100%",
                flexFlow: "column wrap",
                alignContent: "flex-end",
              }}
            >
              Variant Qty: {thisVariantQty}
            </DialogContentText>
          </DialogContent>
        ) : null}

        {/* Declare button for reducing quantity by one */}
        <DialogActions>
          <Button
            onClick={() => handleChangeQuantity(-1)}
            variant="contained"
            color="error"
            endIcon={<RemoveCircleOutlineIcon />}
            disabled={
              chosenColourOption === "" ||
              chosenSizeOption === "" ||
              thisVariantQty <= 0
            }
          >
            {" "}
            Remove One
          </Button>
        </DialogActions>
        {/* Declare button for increasing quantity */}
        <DialogActions>
          <Button
            onClick={() => handleChangeQuantity(1)}
            color="success"
            variant="contained"
            endIcon={<AddCircleOutlineIcon />}
            disabled={chosenColourOption === "" || chosenSizeOption === ""}
          >
            {" "}
            Add One{" "}
          </Button>
        </DialogActions>
        {/* Button for closing dialog box*/}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
