import { IndividualCartItem } from "../../App";
import { httpGet, httpPost } from "../utils/api.utils";

//Function to get the different type options
async function getAllShippingOptions() {
  //Try to laod data
  try {
    const response = await httpGet<any>("checkout/shipping-options");
    return response.data;
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to checkout
async function processCheckout(
  formFirstName: string,
  formLastName: string,
  formCompanyName: string,
  formPhoneNumber: string,
  formSAddressLineOne: string,
  formSAddressLineTwo: string,
  formSAddressLineThree: string,
  formSPostcode: string,
  formCardholdersName: string,
  formCardNumber: string,
  formExpiryDate: string,
  formCVC: string,
  formPAddresLine1: string,
  formPAddressLine2: string,
  formPAddressLine3: string,
  formPPostcode: string,
  shippingOption: number,
  cartItems: IndividualCartItem[]
) {
  //Build request body using passed in parametesr, any
  //null parameters are substituted for empty string or equivalent
  const checkoutBody = {
    first_name: formFirstName,

    last_name: formLastName,

    company_name: formCompanyName ? formCompanyName : "",

    phone_number: formPhoneNumber,

    shipping_address_line_one: formSAddressLineOne,

    shipping_address_line_two: formSAddressLineTwo ? formSAddressLineTwo : "",

    shipping_address_line_three: formSAddressLineThree,

    shipping_address_postcode: formSPostcode,

    cardholders_name: formCardholdersName,

    card_number_last_four_digits:
      formCardNumber.length > 4
        ? formCardNumber.substring(formCardNumber.length - 4)
        : formCardNumber,

    payment_address_line_one: formPAddresLine1,

    payment_address_line_two: formPAddressLine2 ? formPAddressLine2 : "",

    payment_address_line_three: formPAddressLine3,

    payment_address_line_postcode: formPPostcode,

    shippingOption,

    orderItems: cartItems.map((element: IndividualCartItem) => {
      return {
        quantity: element.quantity * -1,
        stockItemID: element.stockID,
        colourOptionID: element.colourID,
        sizeOptionID: element.sizeID,
      };
    }),
  };

  //Try to post data
  try {
    const response = await httpPost("checkout", checkoutBody);
    return response;
    //Throw an error if something goes wrong
  } catch (error) {
    return error;
  }
}

//Export functionality
export { getAllShippingOptions, processCheckout };
