import React from "react";

//Describes user context interface type
interface UserContextInterface {
  userState?: any;
  setUserState?: any;
}

// Exports the UserContext using the interface provided
export const UserContext = React.createContext<UserContextInterface>({});
