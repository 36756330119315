import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//Declares the props that a given ListItem will need
interface StockListItemProps {
  items: ReadonlyArray<{
    id: number;
    name: string;
    price: number;
    imageURL: string;
  }>;
}
//Function that generates JSX for all stock items
export default function StockListItem(props: StockListItemProps) {
  const { items } = props;

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  return (
    <>
      {/* This code is executed as a map ensuring that the same layout is applied to ALL stock items individually */}
      {items.map((thisItem) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          key={"stockItemID" + thisItem.id.toString()}
        >
          {/*As above, each element is setup as a grid item with a width depending on the size of the screen (smaller relative width on larger screen sizes) */}
          <Card>
            <CardActionArea
              onClick={() => redirectToUrl("/stock-item/" + thisItem.id)}
            >
              <CardMedia
                component="img"
                height="140"
                image={thisItem.imageURL}
                alt={"Image of " + thisItem.name}
              />
              {/* Information about this stock item should be shown below */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {thisItem.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {"Price: £" + thisItem.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => redirectToUrl("/stock-item/" + thisItem.id)}
              >
                View Details &gt;&gt;&gt;
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
}
