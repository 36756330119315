import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import { OtherImages } from "./components/OtherImages";
import { StockOptions } from "./components/StockOptions";
import { getOne } from "../../api/stock/stock";

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

const StockItem = () => {
  //Load params from URL
  const params = useParams();

  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = useState<any | undefined>(undefined);

  //Stock data react hook is declared
  const [itemData, setItemData] = useState<any>({});

  //Picture data react hook is declared
  const [pictureData, setPictureData] = useState<
    { imageURL: string; altText: string }[]
  >([]);

  //Colour options hook is declared
  const [colourOptionsHook, setColourChoicesHook] = useState<any[]>([]);

  //Size options react hook is declared
  const [sizeOptionsHook, setSizeChoicesHook] = useState<any[]>([]);

  //Try to load the stock data using the provided params data
  React.useEffect(() => {
    getOne(params.id)
      //If request goes ok, then assign response to stock data react hook
      .then((response) => {
        setItemData(response);
        const formattedPictureData = response.stock_images.map(
          (indPicture: { imageURL: string; id: number }) => {
            return { imageURL: indPicture.imageURL, altText: indPicture.id };
          }
        );
        setPictureData(formattedPictureData);
        setColourChoicesHook(response.colour_options);
        setSizeChoicesHook(response.size_options);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, [params.id]);

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  return (
    <div>
      {/* This page uses a grid layout */}
      <Grid
        container
        spacing={2}
        padding={0}
        sx={{
          padding: "10px",
          marginBottom: "50px",
          marginTop: "0px",
        }}
      >
        {/* The first grid item is an image of the bike with relevant padding*/}
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              width="100%"
              image={itemData.imageURL}
              alt={"Image of " + itemData.name}
              sx={{ padding: "20px" }}
            />
          </Card>
        </Grid>
        {/* The second grid element is the stock options component that displays all the 
        relevant detail and interactivity for the page*/}
        <Grid item xs={12} md={6}>
          <StockOptions
            colourOptions={colourOptionsHook}
            sizeOptions={sizeOptionsHook}
            title={itemData.name}
            price={itemData.price}
            stockQuantity={0}
            stockID={Number(params.id)}
          />
        </Grid>
        {/* There are then a few typography based cards, giving user overview of the item*/}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Description
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {itemData.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Specification
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {itemData.specification}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Last grid item is a collection of any secondary images */}
        <OtherImages pictures={pictureData} />
      </Grid>
    </div>
  );
};

export default StockItem;
