import axios, { AxiosRequestConfig } from "axios";

//Declare the location of the api endpoints on the backend server
const apiPath = "/api/";

//Generate the full API url by merging the backend server URL and api path
const baseURL = process.env.REACT_APP_API_URL + apiPath;

//Create an instance for axios using the url provided
const axiosInstance = axios.create({
  baseURL,
});

//Function will take in an encoded jwtToken and decode it (or return null if token is empty or invalid)
const jwtDecode = (jwtToken: string | undefined) => {
  //If no token provided, instantly return null
  if (!jwtToken) {
    return null;
  }

  //Split the token using the full stops and extract the second portion (the tokens payload)
  const base64Url = jwtToken.split(".")[1];
  //If there is no payload, return null as token invalid
  if (!base64Url) {
    return null;
  }
  //Execute transformation and decoding
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

//This function can be called to set the authentication header on all future requests
const setAuthHeader = (token: string) => {
  // Add Auth header with token.
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

//Function will remove the auth header from all requests
const removeAuthHeader = () => {
  delete axios.defaults.headers.common["Authorization"];
};

// HTTP GET method
const httpGet = async <T>(url: string, config: AxiosRequestConfig = {}) => {
  const response = await axiosInstance.get<T>(url, config);
  return response;
};

// HTTP POST method
const httpPost = async <T>(
  url: string,
  data: any,
  config: AxiosRequestConfig = {}
) => {
  // On post requests we need to specify the content type as application/json
  if (!config.headers) config.headers = {};
  config.headers["Content-Type"] = "application/json";

  //Submit the request
  const response = await axiosInstance.post<T>(
    url,
    JSON.stringify(data),
    config
  );
  return response;
};

// HTTP DELETE method
const httpDelete = async (url: string, config: AxiosRequestConfig = {}) => {
  const response = await axiosInstance.delete(url, config);
  return response;
};
export {
  httpGet,
  httpPost,
  httpDelete,
  setAuthHeader,
  removeAuthHeader,
  jwtDecode,
};
