import React from "react";
import { Card, CardMedia, Grid } from "@mui/material";

//Image carousel component, takes in an array of images and some alt text as a prop
export function OtherImages(props: {
  pictures: { imageURL: string; altText: string }[];
}) {
  const { pictures } = props;

  /* Returns grid of other images */

  return (
    <>
      {/* Only display section header if an image has actually been provided*/}
      {pictures.length >= 1 ? (
        <Grid item xs={12}>
          <h3>Other Pictures</h3>
        </Grid>
      ) : null}

      {/* Generate a map of grids, each containing a single media card with the image on */}
      {pictures.map((indPicture, index) => (
        <Grid item xs={6} md={4} lg={3} key={"otherPictures" + String(index)}>
          <Card>
            <CardMedia
              component="img"
              width="100%"
              image={indPicture.imageURL}
              alt={"Image of " + indPicture.altText}
              sx={{ padding: "20px" }}
            />
          </Card>
        </Grid>
      ))}
    </>
  );
}
