//Function for rendering of StockManagement screen

import React from "react";
import { createOrUpdate, getStockList } from "../../api/stock/stock";
import { IndStockItem } from "./Interfaces/IndStockItem";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import StockManagementListItem from "./Components/StockManagementListItem/StockManagementListItem";

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

const StockManagement = () => {
  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = React.useState<any | undefined>(undefined);
  //Other relevant hookes / states are declared as neeed
  const [stockData, setStockData] = React.useState<IndStockItem[]>([]);
  const [stockUpdate, setStockUpdate] = React.useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = React.useState("");

  //Try to load the stock data
  React.useEffect(() => {
    getStockList(null, null, null, null)
      //If request goes ok, then assign response to associated react hooks
      .then((response) => {
        setStockData(response);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error: any) => setErrorFlag({ error }));
    //If there is any change to the stockUpdate state then the data should be refreshed
  }, [stockUpdate]);

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  //Function to run when trying to create a new item
  const handleCreateNew = async () => {
    try {
      //Attempt to create
      const response = await createOrUpdate({
        name: "",
        description: "",
        specification: "",
        imageURL: "",
        price: 0,
        type_option: 1,
        colour_options: [],
        size_options: [],
        stock_images: [],
      });
      //Remove any notifications
      setNotificationMessage("");

      //Reload window so as to show new item
      window.location.reload();
    } catch {
      //If anything goes wrong, show an error message
      setNotificationMessage(
        "Something Went Wrong While Trying To Add a New Item"
      );
    }
  };

  //Declare JSX to be returned
  return (
    <>
      {/* The page should be wrapped in a grid */}
      <Grid container spacing={2} sx={{ padding: "5px" }}>
        <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
          <Card
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* If there is an error messaeg, display it at the top of the screen */}
            {notificationMessage !== "" ? (
              <CardContent sx={{ textAlign: "right" }}>
                <Typography variant="body2" color={"red"}>
                  {notificationMessage}
                </Typography>
              </CardContent>
            ) : null}
            {/* Button to create new stock item */}
            <CardActions
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "right",
              }}
            >
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={handleCreateNew}
              >
                New Stock Item
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {stockData.length >= 1 ? (
          <>
            {/* A map is generated from the stockData with each item being a StockManagementListItem */}
            {stockData
              .slice()
              .reverse()
              .map((thisItem, index) => (
                <StockManagementListItem
                  {...thisItem}
                  stockUpdate={stockUpdate}
                  setStockUpdate={setStockUpdate}
                  key={"stockManagementList" + index}
                />
              ))}
          </>
        ) : (
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                {/* Show no stock items text */}
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign={"center"}
                >
                  {"No Stock Items Exist"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StockManagement;
