import React, { useState } from "react";
import { getStockList, getAllFilterOptions } from "../../api/stock/stock";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import StockListItem from "./Components/StockListItem";
import FiltersBar from "./Components/FiltersBar";

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

//This generic interface can be used to describe ANY of the various filter options
interface genericFilterOption {
  id: number;
  description: string;
}

//This function will take a possibly null or possible empty string and convert it to an array of numbers
function queryParamToNumberArray(queryArray: string | null): number[] {
  //First check the length (or return a length of 0 if no item)
  return (queryArray?.length ?? 0) >= 1
    ? //As long as length is at least one character then split string into array of numbers, returning an empty array if anything goes wrong
      queryArray?.split(",").map((thisOption: string) => Number(thisOption)) ??
        []
    : [];
}

//Function defines how the stock list page should look
export default function StockList() {
  //page parameters should be loaded using react hook
  const [filterParams] = useSearchParams();

  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = useState<any | undefined>(undefined);

  //Stock data react hook is declared
  const [stockData, setStockData] = useState<any[]>([]);

  //Declares react hooks outlining all the possible options that are available for the filter dropdowns
  const [potentialTypeOptions, setPotentialTypeOptions] = useState<
    genericFilterOption[]
  >([]);

  const [potentialSizeOptions, setPotentialSizeOptions] = useState<
    genericFilterOption[]
  >([]);

  const [potentialColourOptions, setPotentialColourOptions] = useState<
    genericFilterOption[]
  >([]);

  //Try to load the stock data using the provided params data
  React.useEffect(() => {
    getStockList(
      filterParams.get("colourOptions"),
      filterParams.get("sizeOptions"),
      filterParams.get("typeOptions"),
      filterParams.get("searchValue")
    )
      //If request goes ok, then assign response to stock data react hook
      .then((response) => {
        setStockData(response);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, [filterParams]);

  //Try to load the filter options data
  React.useEffect(() => {
    getAllFilterOptions()
      //If request goes ok, then assign response to associated react hooks
      .then(
        (allOptions: {
          typeOptions: any[];
          sizeOptions: any[];
          colourOptions: any[];
        }) => {
          setPotentialTypeOptions(allOptions.typeOptions);
          setPotentialColourOptions(allOptions.colourOptions);
          setPotentialSizeOptions(allOptions.sizeOptions);
        }
      )
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error: any) => setErrorFlag({ error }));
  }, []);

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  //Otherwise return the page contents
  return (
    <div style={{ marginTop: "7px" }}>
      {/* Create an instance of the FiltersBar component passing in the processed filter options 
      (and what options are already selected in the current URL)*/}
      <FiltersBar
        colourOptions={potentialColourOptions}
        sizeOptions={potentialSizeOptions}
        typeOptions={potentialTypeOptions}
        typeOptionExistingIDs={queryParamToNumberArray(
          filterParams.get("typeOptions")
        )}
        colourOptionExistingIDs={queryParamToNumberArray(
          filterParams.get("colourOptions")
        )}
        sizeOptionExistingIDs={queryParamToNumberArray(
          filterParams.get("sizeOptions")
        )}
        searchValue={filterParams?.get("searchValue") ?? undefined}
      />

      {stockData !== undefined && stockData.length >= 1 ? (
        /*Create a grid containing StockListItem components, each component 
      has the information about one stock item passed to it */
        <Grid container spacing={2} sx={{ padding: "5px" }}>
          <StockListItem
            items={
              stockData !== undefined
                ? stockData.map((e) => {
                    return {
                      id: e.id,
                      name: e.name,
                      imageURL: e.imageURL,
                      price: e.price,
                    };
                  })
                : []
            }
          />
        </Grid>
      ) : (
        /* If no items were found, the No Items Found header will be displayed instead of the results grid */
        <h3 style={{ margin: "5px" }}>No Items Found</h3>
      )}
    </div>
  );
}
