import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { getRoutes } from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import React from "react";
import { UserContext } from "./contexts/UserContext/UserContext";
import {
  jwtDecode,
  removeAuthHeader,
  setAuthHeader,
} from "./api/utils/api.utils";
import { CartContext } from "./contexts/CartContext/CartContext";

export const SectionsContext = React.createContext<any[]>([]);

//UserStateInterface is declared here and exported so it can be used throughout the rest of the app
export interface UserStateInterface {
  userRole: string;
  securityToken?: string;
  userEmail?: string;
}

export interface IndividualCartItem {
  stockID: number;
  colourID: number;
  sizeID: number;
  quantity: number;
  maxQuantityForVariant: number;
  unitPrice: number;
}
//CartStateInterface is declared here and exported so it can be used throughout the rest of the app
export interface CartStateInterface {
  cartItems: IndividualCartItem[];
  totalCost: number;
  loadedFromStorage: boolean;
}

//A logoutTimer object is created
let logoutTimer: NodeJS.Timeout;

function App() {
  //The userState state is created with a default value of "userRole: loggedOut"
  const [userState, setUserState] = React.useState<UserStateInterface>({
    userRole: "loggedOut",
  });

  //The cartState state is created with a default value of []
  const [cartState, setCartState] = React.useState<CartStateInterface>({
    cartItems: [],
    totalCost: 0,
    loadedFromStorage: false,
  });

  //Check if a cart is in local storage, if there is, retrieve its contents
  const localStorageCart = localStorage.getItem("BOBS_BIKES_CART");
  if (localStorageCart !== null && cartState.loadedFromStorage === false) {
    const tempCartState = JSON.parse(localStorageCart);
    tempCartState.loadedFromStorage = true;
    //Recalculate total in case this is out of sync on close
    tempCartState.totalCost = tempCartState.cartItems.reduce(
      (accumulator: any, currentItem: any) => {
        return accumulator + currentItem.quantity * currentItem.unitPrice;
      },
      0
    );
    setCartState(tempCartState);
  }

  //Full logic to be executed when an item is added to the cart
  const addToCart = (newItem: IndividualCartItem): boolean => {
    //Setup a temp array for checks to be carried out on
    const tempCartItems = cartState.cartItems;

    //Search to see if the cart already contains an item of this variant
    const existingIndex = tempCartItems.findIndex(
      (element) =>
        element.stockID === newItem.stockID &&
        element.colourID === newItem.colourID &&
        element.sizeID === newItem.sizeID
    );

    //If no item found in cart already
    if (existingIndex === -1) {
      //Double check that a quantity of more than possible is not being added
      if (
        newItem.quantity <= newItem.maxQuantityForVariant &&
        newItem.quantity >= 1
      ) {
        tempCartItems.push(newItem);
        //If it cannot be added return false to signal an error
      } else {
        return false;
      }
      //If item does already exist
    } else {
      //Make sure there is enough stock to add another item to cart
      if (
        tempCartItems[existingIndex].quantity + newItem.quantity <=
          newItem.maxQuantityForVariant &&
        tempCartItems[existingIndex].quantity + newItem.quantity >= 0
        //Then add item and update maxQuantity to reflect current stock levels
      ) {
        tempCartItems[existingIndex].quantity += newItem.quantity;
        tempCartItems[existingIndex].maxQuantityForVariant =
          newItem.maxQuantityForVariant;
        //If there is a quantity related issue then return an error
      } else {
        return false;
      }

      //Check whether the latest update has removed an item from cart. Remove it if it has
      if (tempCartItems[existingIndex].quantity <= 0) {
        tempCartItems.splice(existingIndex, 1);
      }
    }
    //Calculate new total cost
    const newTotalCost = tempCartItems.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.quantity * currentItem.unitPrice;
    }, 0);

    //Save cart to state
    setCartState({
      cartItems: tempCartItems,
      totalCost: newTotalCost,
      loadedFromStorage: cartState.loadedFromStorage,
    });

    //save cart state to local storage
    localStorage.setItem("BOBS_BIKES_CART", JSON.stringify(cartState));
    return true;
  };

  // Set a timeout to log user out automatically
  React.useEffect(() => {
    //When the userState changes, check to see if there is a a securityToken present
    if (userState.securityToken) {
      //If there is, decode the token
      const { exp } = jwtDecode(userState.securityToken);

      //Calculate the time left before it expires and set a timer for that length
      const remainingTime = exp * 1000 - Date.now();
      logoutTimer = setTimeout(() => {
        alert(
          "You're session has expired and you have been logged out for security"
        );
        //When it runs out, remove token from local storage and from the API headers.
        //Also update state to loggedOut
        localStorage.removeItem("BOBS_BIKES_JWT");
        removeAuthHeader();
        setUserState({ userRole: "loggedOut" });
      }, remainingTime);
    } else {
      //If there is no longer any token then remove from other associated locations and remove the timer
      clearTimeout(logoutTimer);
    }
  }, [userState]);

  //Attempt to retrieve token from localStorage. If no token found then set to undefined
  const localStorageToken =
    localStorage?.getItem("BOBS_BIKES_JWT") ?? undefined;

  //Decode the token as needed
  const decodedToken = jwtDecode(localStorageToken);
  //If the token has not yet expired and there is a difference between the current user state and that logged in the local storage
  if (
    Date.now() <= decodedToken?.exp * 1000 &&
    decodedToken?.role !== userState.userRole
  ) {
    //Set user state accordingly
    setUserState({
      userRole: decodedToken.role,
      userEmail: decodedToken.sub,
      securityToken: localStorageToken,
    });
    setAuthHeader(localStorageToken ? localStorageToken : "");
  }

  //Acceptable routes for that role are then retrieved
  const { routerOptions, routesStringArr } = getRoutes(userState.userRole);

  //And a router created using those routes
  const router = createBrowserRouter(routerOptions);

  //Sections (what is visible in the navbar) are also then defined based on user role
  const sections = [
    { title: "Road", url: "/stock-list?typeOptions=1" },
    { title: "E-Bike", url: "/stock-list?typeOptions=2" },
    { title: "Mountain Bike", url: "/stock-list?typeOptions=3" },
  ];

  if (routesStringArr.includes("stock-management")) {
    sections.push({ title: "Stock Management", url: "/stock-management" });
  }

  if (routesStringArr.includes("user-management")) {
    sections.push({ title: "User Management", url: "/user-management" });
  }

  //Generate default userContextValue using the eaerlier calculated userState
  const userContextValue = { userState, setUserState };

  //Generate deafult cartContextValue using the earlier calculated cartState
  const cartContextValue = { cartState, setCartState, addToCart };

  //Function returns JSX describing the required context wrappers and the applications router
  return (
    <UserContext.Provider value={userContextValue}>
      <CartContext.Provider value={cartContextValue}>
        <SectionsContext.Provider value={sections}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </SectionsContext.Provider>
      </CartContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
