import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getOne } from "../../../../api/stock/stock";
import StockAdjust from "../StockAdjust/StockAdjust";

//Declares the props that a given CartItem will need
interface CartItemProps {
  stockID: number;
  colourID: number;
  sizeID: number;
  quantity: number;
}

// Create currency formatter using en-GB
const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

//Function that generates JSX for all cart items
export default function CartItem(props: CartItemProps) {
  const { stockID, colourID, sizeID, quantity } = props;
  const [liveQuantity, setLiveQuantity] = React.useState(quantity);

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  //Declare necessary states
  const [thisStockItem, setThisStockItem] = React.useState<any>();
  const [errorFlag, setErrorFlag] = React.useState<any | undefined>(undefined);

  //Try to load the stock data using the provided id
  React.useEffect(() => {
    getOne(String(stockID))
      //If request goes ok, then assign response to stock data react hook
      .then((response) => {
        setThisStockItem(response);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, [stockID]);

  //If something has gone wrong, will throw an error
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  return (
    <>
      {/*Create a row to show one cart item */}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        item
        xs={12}
      >
        {/* Ind Cart Item Text Card*/}
        <Grid item xs={12} sm={8} className="reactiveGrid" alignItems="stretch">
          <Card
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Information about this stock item and users selections should be shown below */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {thisStockItem?.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {"Size Option: " +
                  thisStockItem?.size_options.find(
                    (element: any) => element.id === sizeID
                  )?.description}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {"Colour Option: " +
                  thisStockItem?.colour_options.find(
                    (element: any) => element.id === colourID
                  )?.description}
              </Typography>
            </CardContent>

            {/* Create a Stock Adjust widget allowing the user to 
            adjust the quantity of this item in their cart */}
            <CardActions>
              <StockAdjust
                stockID={stockID}
                colourID={colourID}
                sizeID={sizeID}
                quantity={{ liveQuantity, setLiveQuantity }}
                price={thisStockItem?.price}
              />
            </CardActions>

            <CardContent>
              <Typography variant="body1" color="text.secondary">
                {"Price: " +
                  formatter.format(thisStockItem?.price * liveQuantity)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Ind Cart Item Image Card*/}
        <Grid item xs={12} sm={4}>
          <Card sx={{ height: "100%" }}>
            <CardMedia
              component="img"
              image={thisStockItem?.imageURL}
              alt={"Image of " + thisStockItem?.name}
              sx={{ height: "100%" }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
