import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

//Declares the props that a given dropdown will need
interface SingleChoiceDropdownProps {
  potentialOptions: ReadonlyArray<{
    id: number;
    description: string;
  }>;
  existingIDs: number | undefined;
  defaultURL: string;
  labelText: string;
}

//Exports SingleChoiceDropdown, this component is very similar to Generic Filter but only allowing a single
//Option to be selected rather than multiple at once
export default function SingleChoiceDropdown(props: SingleChoiceDropdownProps) {
  //Loads the variables from provided props
  const { potentialOptions, existingIDs, defaultURL, labelText } = props;
  //Create navigate function
  const navigate = useNavigate();

  let selectedTypeDescription: string;

  //Do a lookup of the arrays to get the description of the provided option IDs
  selectedTypeDescription =
    potentialOptions.find((thisType) => thisType.id === existingIDs)
      ?.description ?? "";

  //Set constants and config props for filter menu items
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //Declares function that gets executed when an option is selected in dropdown
  const handleTypeChange = (
    event: SelectChangeEvent<typeof selectedTypeDescription>
  ) => {
    const {
      target: { value },
    } = event;

    //Depending on type of receives value, set new value to undefined
    const formattedNewValues = typeof value === "string" ? value : undefined;

    //Set newIDs to be an array of IDs based on a lookup of the descriptions
    const newID =
      potentialOptions.find(
        (anExistingValue) => anExistingValue.description === formattedNewValues
      )?.id ?? "";
    //Redirect to the URL containing the newly found ids
    navigate(defaultURL + newID);
  };

  //Returns JSX code defining layout of a filter
  return (
    <FormControl sx={{ p: 1, m: 0, height: "100%", width: "100%" }}>
      <InputLabel id="typeOptionsDropdown">{labelText}</InputLabel>
      {/* Defines the filter dropdown menu passing in the provided options, 
      and setting value to the already chosen value */}
      <Select
        labelId="typeOptionsDropdownLabel"
        id="typeOptionsDropdownCheckbox"
        value={selectedTypeDescription}
        onChange={handleTypeChange}
        input={<OutlinedInput label={labelText} />}
        renderValue={(selected) => selected}
        MenuProps={MenuProps}
      >
        {/*Defines JSX for each dropdown option */}
        {potentialOptions.map((type) => (
          <MenuItem key={type.id} value={type.description}>
            <ListItemText primary={type.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
