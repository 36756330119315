import { httpDelete, httpGet, httpPost } from "../utils/api.utils";

//Function will take in search parameters and submit them to the relevant backend API endpoint
async function getStockList(
  colourOptions: string | null,
  sizeOptions: string | null,
  typeOptions: string | null,
  searchValue: string | null
) {
  //Define URL and params to be submitted to that URL
  let url = "stock";
  let params: {
    colourOptions?: string | null;
    sizeOptions?: string | null;
    typeOptions?: string | null;
    searchValue?: string | null;
  } = {};

  //If the user is carrying out a search as well as filter, request should be redirected to relevant endpoint
  if (searchValue) {
    url += "/search";
    params.searchValue = searchValue;
  }

  //Only include futher params if they have been provided
  if (colourOptions) {
    params.colourOptions = colourOptions;
  }

  if (sizeOptions) {
    params.sizeOptions = sizeOptions;
  }

  if (typeOptions) {
    params.typeOptions = typeOptions;
  }

  //Try to laod data
  try {
    const response = await httpGet<any>(url, {
      params,
    });
    return response.data;
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to get the different type options
async function getAllFilterOptions() {
  //Try to laod data
  try {
    const typeResponse = await httpGet<any>("stock/allTypes");
    const sizeResponse = await httpGet<any>("stock/size-options");
    const colourResponse = await httpGet<any>("stock/colour-options");
    return {
      typeOptions: typeResponse.data,
      sizeOptions: sizeResponse.data,
      colourOptions: colourResponse.data,
    };
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function get one specific item
async function getOne(itemID: string | undefined) {
  //Try to laod data
  try {
    const stockResponse = await httpGet<any>("stock/" + itemID);
    return stockResponse.data;
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to get stock quantity
async function getStockQty(
  itemID: number | undefined,
  sizeID: number | undefined,
  colourID: number | undefined
) {
  try {
    //Make sure that a valid ID has been provided for all parameters
    if (
      itemID !== undefined &&
      sizeID !== undefined &&
      colourID !== undefined &&
      itemID !== 0 &&
      sizeID !== 0 &&
      colourID !== 0
    ) {
      //If valid parameters have been provided, return result of get request
      const stockQtyResponse = await httpGet<any>(
        `stock/${itemID}/quantity?sizeOptionID=${sizeID}&colourOptionID=${colourID}`
      );
      return stockQtyResponse.data;
      //If valid parameters have not been provided return -1
    } else {
      return -1;
    }
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to get all the type options that should be available to the user
async function getTypeOptions() {
  //Try to load data
  try {
    const response = await httpGet<any>("stock/allTypes");
    return response;
    //Throw an error if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to delete a stock item
async function deleteStockItem(itemID: number) {
  //Define URL
  let url = "stock/" + itemID;

  //Try to delete
  try {
    const response = await httpDelete(url);
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to create a new item or update an existing one
async function createOrUpdate(params: {
  id?: number;
  name: string;
  description: string;
  specification: string;
  imageURL: string;
  price: number;
  type_option: number;
  colour_options: string[];
  size_options: string[];
  stock_images: string[];
}) {
  //Define URL
  let url = "stock/newAndUpdate";

  //Construct request body using provided details
  const reqBody: any = {
    name: params.name,
    description: params.description,
    specification: params.specification,
    imageURL: params.imageURL,
    price: params.price,
    type_option: params.type_option,
    colour_options: params.colour_options,
    size_options: params.size_options,
    stock_images: params.stock_images,
  };

  //If an id was provided, add to request body
  if (params.id) {
    reqBody.id = params.id;
  }

  //Try to submit data
  try {
    const response = await httpPost<any>(url, reqBody);
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Function to update the quantity of a stock item
async function updateQuantity(
  stockItemID: number,
  sizeOptionID: number,
  colourOptionID: number,
  quantity: number
) {
  //Try to submit data
  try {
    const response = await httpPost<any>("stock/updateQuantity", {
      stockItemID,
      sizeOptionID,
      colourOptionID,
      quantity,
    });
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    throw error;
  }
}

//Export functionality
export {
  getStockList,
  getAllFilterOptions,
  getOne,
  getStockQty,
  getTypeOptions,
  deleteStockItem,
  createOrUpdate,
  updateQuantity,
};
