import React, { useState } from "react";
import { Button, Card, CardActions, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import SingleChoiceDropdown from "../../../components/shared/SingleChoiceDropdown/SingleChoiceDropdown";
import { getStockQty } from "../../../api/stock/stock";
import {
  CartContext,
  CartContextInterface,
} from "../../../contexts/CartContext/CartContext";

// Create currency formatter using en-GB
const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

//Declares the props that a given StockOption will need
interface StockOptionsProps {
  sizeOptions: { id: number; description: string }[];
  colourOptions: { id: number; description: string }[];
  title: string;
  price: number;
  stockQuantity: number;
  stockID: number;
}

//StockOptions component, providing all interactivity elements to the StockItem screen
export function StockOptions(props: StockOptionsProps) {
  const { sizeOptions, colourOptions, title, price, stockQuantity, stockID } =
    props;

  //Load cart state from cart context
  const { addToCart }: CartContextInterface = React.useContext(CartContext);

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  //page parameters should be loaded using react hook
  const [choiceParams] = useSearchParams();

  //This block of code will return either undefined or a Number representing the choice paremeter passed in the query string
  let sizeChoiceID: number | undefined;
  const sizeParameter = choiceParams.get("sizeOption");
  if (sizeParameter === null || sizeParameter === undefined) {
    sizeChoiceID = undefined;
  } else {
    sizeChoiceID = Number(sizeParameter);
  }

  //As above, performs same checks for colour parameter
  let colourChoiceID: number | undefined;
  const colourParameter = choiceParams.get("colourOption");
  if (colourParameter === null || colourParameter === undefined) {
    colourChoiceID = undefined;
  } else {
    colourChoiceID = Number(colourParameter);
  }

  //Sets the base URL that should be reset to after any changes
  const baseURL = "/stock-item/" + stockID + "?";

  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = useState<any | undefined>(undefined);

  //Error fulfilling order flag to show text if there was an issue fulfilling the order
  const [errorAddingToCart, setErrorAddingToCart] = useState("");

  //Stock data react hook is declared
  const [variantQuantity, setVariantQuantity] = useState<number>(-1);

  //Logic to be executed when the checkout button is pressed
  const addToCartHandler = (
    checkoutSizeChoiceID: number | undefined,
    checkoutColourChoiceID: number | undefined,
    checkoutStockID: number | undefined
  ) => {
    //Attempt to add one of the chosen items to cart
    if (
      addToCart({
        stockID: checkoutStockID,
        colourID: checkoutColourChoiceID,
        sizeID: checkoutSizeChoiceID,
        quantity: 1,
        maxQuantityForVariant: variantQuantity,
        unitPrice: price,
        //If succesful, travel to cart
      })
    ) {
      redirectToUrl("/cart ");
      //Otherwise, show error that too many of this item already exist in cart
    } else {
      setErrorAddingToCart(
        "You have too many of this item in your cart to add any more"
      );
    }
  };

  //Try to load the stock data using the provided params data
  React.useEffect(() => {
    getStockQty(stockID, sizeChoiceID, colourChoiceID)
      //If request goes ok, then assign response to stock data react hook
      .then((response) => {
        setVariantQuantity(response);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, [stockID, sizeChoiceID, colourChoiceID]);

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  return (
    <>
      {/* Card is declared with a flex column type, vertically aligning all elements */}
      <Card
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* First title of item is shown */}
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ width: "100%", height: "100%", textAlign: "right" }}
        >
          {title}
        </Typography>

        {/* Dropdown is then rendered for both size and 
        colour choices, passing in relevant values from query string */}
        <SingleChoiceDropdown
          potentialOptions={sizeOptions}
          existingIDs={sizeChoiceID}
          defaultURL={
            baseURL +
            "colourOption=" +
            (colourChoiceID ? colourChoiceID : "") +
            "&sizeOption="
          }
          labelText="Size Option"
        />

        <SingleChoiceDropdown
          potentialOptions={colourOptions}
          existingIDs={colourChoiceID}
          defaultURL={
            baseURL +
            "sizeOption=" +
            (sizeChoiceID ? sizeChoiceID : "") +
            "&colourOption="
          }
          labelText="Colour Option"
        />
        {/* This block of code will only show if there was an error while adding the item to cart */}
        {errorAddingToCart !== "" ? (
          /* Further information then shown using typography components */
          <Typography
            variant="body2"
            color={"red"}
            sx={{ width: "100%", height: "100%", textAlign: "right" }}
          >
            {/* The set error message will be shown*/}
            {errorAddingToCart}
          </Typography>
        ) : null}

        {/* This block of code will only show stock data once a valid size/colour combo have been chosen*/}
        {variantQuantity >= 0 ? (
          /* Further information then shown using typography components */
          /* Text colour is set dependent on amount left in stock */
          <Typography
            variant="body2"
            color={variantQuantity >= 1 ? "green" : "red"}
            sx={{ width: "100%", height: "100%", textAlign: "right" }}
          >
            {variantQuantity >= 1
              ? variantQuantity + " in Stock"
              : "Out of Stock"}
          </Typography>
        ) : null}

        {/* Price is shown, converted to string using premade currency formatter function */}
        <Typography
          variant="body2"
          sx={{ width: "100%", height: "100%", textAlign: "right" }}
        >
          Price: {formatter.format(price)}
        </Typography>

        {/* Button area, declaring button to add to cart */}
        <CardActions
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "right",
          }}
        >
          {/* On button press, details are passed to addToCartHandler function. Only if their choices are valid */}
          <Button
            color="primary"
            variant="contained"
            disabled={!(sizeChoiceID && colourChoiceID) || variantQuantity <= 0}
            onClick={() => {
              addToCartHandler(sizeChoiceID, colourChoiceID, stockID);
            }}
          >
            Add to Cart &gt;&gt;&gt;
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
