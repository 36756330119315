import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { Box, CssBaseline } from "@mui/material";
import Footer from "../Footer/Footer";

//Defines the main page components, Outlet will contain the page specific contents
const Layout = () => {
  return (
    <>
      {/* Will load custom Navbar component at top of page */}
      <Navbar />

      {/* Box should be created to display main page contents, box contains a div that will fill screen width */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <div className="app" style={{ flex: "1", maxWidth: "100%" }}>
          <Outlet />
        </div>
      </Box>

      {/* Footer should then be shown at the bottom of the page */}
      <Footer />
    </>
  );
};

export default Layout;
