import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { IndStockItem } from "../../Interfaces/IndStockItem";
import React from "react";
import {
  createOrUpdate,
  deleteStockItem,
  getTypeOptions,
} from "../../../../api/stock/stock";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router";
import StockQtyAdjust from "../StockQtyAdjust/StockQtyAdjust";

//This component will take in all the normal StockItem data but also a stockUpdate state that can be used to trigger a refresh of stock screen information
interface StockManagementListItemProps extends IndStockItem {
  stockUpdate: any;
  setStockUpdate: any;
}

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

export default function StockManagementListItem(
  props: StockManagementListItemProps
) {
  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = React.useState<any | undefined>(undefined);

  //Setup other necessary states / hooks
  const [notificationMessage, setNotificationMessage] = React.useState({
    type: "error",
    description: "",
  });

  const [typeDropdownValues, setTypeDropdownValues] = React.useState<string[]>(
    []
  );

  const [typeDropdownObjects, setTypeDropdownObjects] = React.useState<any[]>();

  const [inEditMode, setInEditMode] = React.useState<boolean>(false);

  const thisItem: StockManagementListItemProps = props;

  const refreshAllStock = () => {
    thisItem.setStockUpdate(!thisItem.stockUpdate);
  };

  //Declare special values to track as part of updates to text fields
  const [stockName, setStockName] = React.useState(thisItem.name);
  const [stockDescription, setStockDescription] = React.useState(
    thisItem.description
  );
  const [stockSpecification, setStockSpecification] = React.useState(
    thisItem.specification
  );
  const [stockImageURL, setStockImageURL] = React.useState(thisItem.imageURL);
  const [stockPrice, setStockPrice] = React.useState(thisItem.price);
  const [stockType, setStockType] = React.useState(
    thisItem.type_option.description
  );
  const [stockColourOptions, setStockColourOptions] = React.useState(
    thisItem.colour_options
      .map((thisColour) => thisColour.description)
      .join(",")
  );
  const [stockSizeOptions, setStockSizeOptions] = React.useState(
    thisItem.size_options.map((thisSize) => thisSize.description).join(",")
  );
  const [stockSecondaryImages, setStockSecondaryImages] = React.useState(
    thisItem.stock_images.map((thisImage) => thisImage.imageURL).join(",")
  );

  //Try to load the type options
  React.useEffect(() => {
    getTypeOptions()
      //If request goes ok, then assign response to associated react hooks
      .then((response) => {
        setTypeDropdownValues(
          response.data.map((element: any) => element.description)
        );
        setTypeDropdownObjects(response.data);
      })
      //If something goes wrong then setErrorFlag error attribute to equal the error that was caught
      .catch((error: any) => setErrorFlag({ error }));
    //If there is any change to the stockUpdate state then the data should be refreshed
  }, []);

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  //Function to execute when the user tries to update an item
  const handleUpdate = async () => {
    //First check if item is in in edit mode
    if (inEditMode) {
      //If so carry out all necessary conversions between input data and what needs to be stored
      const selectedTypeOptionID =
        typeDropdownObjects?.find(
          (element: any) => element.description === stockType
        ).id ?? -1;
      const parsedColourOptions = stockColourOptions.split(",");
      const parsedSizeOptions = stockSizeOptions.split(",");
      const parsedSecondaryImages = stockSecondaryImages.split(",");
      //Try to submit data
      try {
        const response = await createOrUpdate({
          id: thisItem.id,
          name: stockName,
          description: stockDescription,
          specification: stockSpecification,
          imageURL: stockImageURL,
          price: stockPrice,
          type_option: selectedTypeOptionID,
          colour_options: parsedColourOptions,
          size_options: parsedSizeOptions,
          stock_images: parsedSecondaryImages,
        });
        //If succesful quite edit mode, set a success message and refresh screen to reflect all changes
        setInEditMode(false);
        setNotificationMessage({
          type: "success",
          description: "Item updated succesfully",
        });
        refreshAllStock();
        //If there was a problem show an error message instead
      } catch {
        setNotificationMessage({
          type: "error",
          description:
            "There was an issue updating this item, please check your values. Type Options, Colour Options, and Secondary Images should be seperated by commas",
        });
      }
      //If update was chosen but not already in edit mode, change to edit mode
    } else {
      setInEditMode(true);
    }
  };

  //Function to handle delete logic
  const handleDelete = async () => {
    //Try to delete the item and refresh the screen if succesful
    try {
      await deleteStockItem(thisItem.id);
      window.location.reload();
      //If there was an issue throw an error instead
    } catch {
      setNotificationMessage({
        type: "success",
        description:
          "Something went wrong while deleting this item, please try again",
      });
    }
  };

  //Returns JSX describing layout
  return (
    <>
      <Grid item xs={12}>
        {/* Card is placed in a grid item with full screen width */}
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} display={"flex"} flexDirection={"row"}>
              <CardContent sx={{ alignSelf: "center", width: "100%" }}>
                {/* If an image was provided, display it*/}
                {thisItem.imageURL !== "" ? (
                  <CardMedia
                    component="img"
                    image={thisItem.imageURL}
                    alt={"Image of " + thisItem.name}
                  />
                ) : null}
              </CardContent>
            </Grid>
            <Grid item xs={12} md={8}>
              {/* Information about this stock item should be shown below, fields are only editable if currently in edit mode */}
              <CardContent>
                <TextField
                  label="Name"
                  value={stockName}
                  onChange={(e) => setStockName(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Description"
                  value={stockDescription}
                  onChange={(e) => setStockDescription(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Specification"
                  value={stockSpecification}
                  onChange={(e) => setStockSpecification(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Image URL"
                  value={stockImageURL}
                  onChange={(e) => setStockImageURL(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Price"
                  value={stockPrice}
                  onChange={(e) =>
                    setStockPrice(
                      !isNaN(Number(e.target.value))
                        ? Number(e.target.value)
                        : stockPrice
                    )
                  }
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <FormControl
                  sx={{ margin: "5px", height: "100%", width: "100%" }}
                  disabled={!inEditMode}
                >
                  <InputLabel id="typeOptionsDropdown">
                    {"Stock Type"}
                  </InputLabel>
                  {/* Defines the filter dropdown menu passing in the provided options, 
      and setting value to the already chosen value */}
                  <Select
                    labelId="typeOptionsDropdownLabel"
                    id="typeOptionsDropdownCheckbox"
                    value={
                      typeDropdownValues.includes(stockType) ? stockType : ""
                    }
                    onChange={(e) => setStockType(e.target.value)}
                    input={<OutlinedInput label={"Stock Type"} />}
                    renderValue={(selected) => selected}
                  >
                    {/*Defines JSX for each dropdown option */}
                    {typeDropdownValues.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        <ListItemText primary={type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Colour Options"
                  value={stockColourOptions}
                  onChange={(e) => setStockColourOptions(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Size Options"
                  value={stockSizeOptions}
                  onChange={(e) => setStockSizeOptions(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
                <TextField
                  label="Secondary Images"
                  value={stockSecondaryImages}
                  onChange={(e) => setStockSecondaryImages(e.target.value)}
                  fullWidth
                  sx={{ margin: "5px" }}
                  disabled={!inEditMode}
                />
              </CardContent>
              {/* Show notification message if one is present and set colour dependent on type */}
              {notificationMessage.description !== "" ? (
                <CardContent sx={{ textAlign: "right" }}>
                  <Typography
                    variant="body2"
                    color={
                      notificationMessage.type === "error" ? "red" : "green"
                    }
                  >
                    {notificationMessage.description}
                  </Typography>
                </CardContent>
              ) : null}

              <CardActions
                sx={{
                  padding: "10px",
                  display: "flex",
                  width: "100%",
                  flexFlow: "column wrap",
                  alignContent: "flex-end",
                }}
              >
                {/* Define button options */}
                {/* Update button will change styling to depending on mode */}
                <Button
                  size="small"
                  color="primary"
                  variant={inEditMode ? "contained" : "outlined"}
                  onClick={handleUpdate}
                  startIcon={<RefreshIcon />}
                >
                  Update
                </Button>
              </CardActions>
              <CardActions
                sx={{
                  padding: "10px",
                  display: "flex",
                  width: "100%",
                  flexFlow: "column wrap",
                  alignContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={handleDelete}
                  startIcon={<DeleteIcon />}
                >
                  DELETE
                </Button>
              </CardActions>
              <CardActions
                sx={{
                  padding: "10px",
                  display: "flex",
                  width: "100%",
                  flexFlow: "column wrap",
                  alignContent: "flex-end",
                }}
              >
                {/* Loads Stock Qty Adjust Component that will allow adjusting of stock levels */}
                <StockQtyAdjust
                  stockID={thisItem.id}
                  colourOptions={thisItem.colour_options}
                  sizeOptions={thisItem.size_options}
                />
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
