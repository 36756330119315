import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

//Declares the props that a given Filter will need
interface GenericFilterProps {
  potentialOptions: ReadonlyArray<{
    id: number;
    description: string;
  }>;
  existingIDs: ReadonlyArray<number>;
  defaultURL: string;
  labelText: string;
}

export default function GenericFilter(props: GenericFilterProps) {
  //Loads the variable from provided props
  const { potentialOptions, existingIDs, defaultURL, labelText } = props;
  //Create navigate function
  const navigate = useNavigate();

  //Do a lookup of the arrays to get the description of the provided option IDs
  const selectedTypesDescriptions = potentialOptions
    .filter((thisType) => existingIDs.includes(thisType.id))
    .map((thisType) => thisType.description);

  //Set constants and config props for filter menu items
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //Declares function that gets executed when an option is selected in dropdown
  const handleTypeChange = (
    event: SelectChangeEvent<typeof selectedTypesDescriptions>
  ) => {
    const {
      target: { value },
    } = event;

    //Depending on type of receives value, convert to array of values
    const formattedNewValues =
      typeof value === "string" ? value.split(",") : value;

    //Set newIDs to be an array of IDs based on a lookup of the descriptions
    const newIDs = formattedNewValues
      .map((element) =>
        potentialOptions.find(
          (anExistingValue) => anExistingValue.description === element
        )
      )
      .map((foundElement) => foundElement?.id);
    //Redirect to the URL containing the newly found ids
    navigate(defaultURL + newIDs.join(","));
  };

  //Returns JSX code defining layout of a filter
  return (
    <FormControl sx={{ m: 1, width: 200 }}>
      <InputLabel id="typeOptionsDropdown">{labelText}</InputLabel>
      {/* Defines the filter dropdown menu as checkboxes, passing in the provided options, 
      and setting value to the already chosen values */}
      <Select
        labelId="typeOptionsDropdownLabel"
        id="typeOptionsDropdownCheckbox"
        multiple
        value={selectedTypesDescriptions}
        onChange={handleTypeChange}
        input={<OutlinedInput label={labelText} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {/*Defines JSX for each dropdown option */}
        {potentialOptions.map((type) => (
          <MenuItem key={type.id} value={type.description}>
            {/* Will mark an item as already checked if it is present in the array of descriptions */}
            <Checkbox
              checked={selectedTypesDescriptions.indexOf(type.description) > -1}
            />
            <ListItemText primary={type.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
