import React from "react";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { UserStateInterface } from "../../App";
import {
  Button,
  Card,
  CardActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  loginAttempt,
  signupAttempt,
} from "../../api/userAccounts/userAccounts";
import { jwtDecode, setAuthHeader } from "../../api/utils/api.utils";

//Login container function
const Login = () => {
  //Load user state functionality from UserContext
  const {
    setUserState,
  }: { userState?: UserStateInterface; setUserState?: any } =
    React.useContext(UserContext);

  //Establish various react hooks that will be utilised throughout this page
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>("");
  const [loginType, setLoginType] = React.useState<string>("login");

  const [badDetails, setBadDetails] = React.useState<{
    problem: boolean;
    description: string;
  }>({ problem: false, description: "" });

  //Function that can take a token response (either from loginn or signup attempt)
  //and take relevant next steps
  const handleTokenResponse = (tokenResponse: any) => {
    //If the response from the login attempt is 201 then it was succesful, the user should be logged in
    if (tokenResponse?.status === 201) {
      const decodedToken = jwtDecode(tokenResponse.data);
      //Add provided token data to localStorage so it will be present after page refreshes,
      //set the auth header for future api requests and set userState to trigger FE visual changes
      localStorage.setItem("BOBS_BIKES_JWT", tokenResponse.data);
      setAuthHeader(tokenResponse.data);
      setUserState({
        userRole: decodedToken.role,
        userEmail: decodedToken.sub,
        securityToken: tokenResponse.data,
      });
    } else {
      //If not, they provided bad details and should be prompted for more
      setBadDetails({
        problem: true,
        description: "Invalid details provided, please try again",
      });
    }
  };

  //This function is executed when a user tries to login
  const handleLogin = async () => {
    //It first checks to see if the screen is currently in "login" mode.
    //If not, it switches all elements to login mode but makes no further progress
    if (loginType === "login") {
      //It then checks that a username and password have both been provided
      if (username.length >= 1 && password.length >= 1) {
        //makes the login request
        const loginResponse: any = await loginAttempt(username, password);
        //This function will then process the response
        handleTokenResponse(loginResponse);
        //If username or password not provided, throw error
      } else {
        setBadDetails({
          problem: true,
          description: "An email and password must be provided",
        });
      }
      //If screen not currently in login mode, it should be switched before any further action
    } else {
      setLoginType("login");
      setBadDetails({ problem: false, description: "" });
    }
  };

  //Function to be executed when a user tries to signup
  const handleSignup = async () => {
    //If loginType is signup, continue. Otherwise, change to signup method
    if (loginType === "signup") {
      //First check that all relevant parameters have actually been completed
      if (
        username.length >= 1 &&
        password.length >= 1 &&
        passwordConfirm.length >= 1
      ) {
        //If the passwords do not match, throw an error
        if (password !== passwordConfirm) {
          setBadDetails({
            problem: true,
            description: "Passwords do not match",
          });
        } else {
          //If all inputs are good, then fetch the response and process with the handleTokenResponse function
          const signupResponse: any = await signupAttempt(username, password);
          handleTokenResponse(signupResponse);
        }

        //If username, password, or password confirm not provided, throw error
      } else {
        setBadDetails({
          problem: true,
          description: "An email and password must be provided",
        });
      }
      //If screen not currently in signup mode then switch
    } else {
      setLoginType("signup");
      setBadDetails({ problem: false, description: "" });
    }
  };

  //Return visual layout for page
  return (
    //Grid to contain login elements
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginTop="10px"
      padding="5px"
    >
      {/* Use a single grid item to arrange login card on screen*/}
      <Grid item xs={12} sm={6} md={4}>
        {/* Place all login elements inside a formatted card element, if the user presses enter key then login should be processed */}
        <Card
          sx={{
            width: "100%",
            height: "100%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              /* Take action dependent on what mode the screen is in */
              loginType === "login" ? handleLogin() : handleSignup();
            }
          }}
        >
          {/* Text field that user can interact with, loads and sets values to username react hook*/}
          <TextField
            id="emailTextBox"
            label="Email"
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: true }}
            focused={false}
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
            margin="dense"
          />

          {/* Same as above */}
          <TextField
            id="passwordTextBox"
            label="Password"
            variant="filled"
            type="password"
            size="small"
            InputLabelProps={{ shrink: true }}
            focused={false}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            margin="dense"
          />

          {/* Only show next section if screen in signup mode*/}
          {loginType === "signup" ? (
            /* A third text field for password confirm box for when user is in signup */
            <TextField
              id="passwordConfirmTextBox"
              label="Confirm Password"
              variant="filled"
              type="password"
              size="small"
              InputLabelProps={{ shrink: true }}
              focused={false}
              value={passwordConfirm}
              onChange={(e: any) => setPasswordConfirm(e.target.value)}
              margin="dense"
            />
          ) : null}

          {/* Only show error message if bad details have been entered */}
          {badDetails ? (
            /* Further information then shown using typography components */
            <Typography
              variant="body2"
              color={"red"}
              sx={{ width: "100%", height: "100%", textAlign: "right" }}
            >
              {badDetails.description}
            </Typography>
          ) : null}

          {/* Card Actions stored in this area */}
          <CardActions
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "right",
            }}
          >
            {/* On button press, handleLogin function is called to process page */}
            {/* Button variant changes depending on the screen mode */}
            <Button
              color="primary"
              variant={loginType === "login" ? "contained" : "outlined"}
              onClick={() => {
                handleLogin();
              }}
            >
              Login &gt;&gt;&gt;
            </Button>

            {/* On button press, handleSignup function is called to process page */}
            {/* Button variant changes depending on the screen mode */}
            <Button
              color="primary"
              variant={loginType === "signup" ? "contained" : "outlined"}
              onClick={() => {
                handleSignup();
              }}
            >
              Signup &gt;&gt;&gt;
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
