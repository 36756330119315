import React from "react";

//Describes cart context interface type
export interface CartContextInterface {
  cartState?: any;
  setCartState?: any;
  addToCart(newItem: any): boolean;
}

// Exports the CartContext using the interface provided
export const CartContext = React.createContext<CartContextInterface>({
  addToCart: () => false,
});
