import { Navigate } from "react-router-dom";
import Layout from "../components/shared/Layout/Layout";
import Home from "../containers/Home/Home";
import Cart from "../containers/Cart/Cart";
import Checkout from "../containers/Checkout/Checkout";
import Login from "../containers/Login/Login";
import StockItem from "../containers/StockItem/StockItem";
import StockList from "../containers/StockList/StockList";
import UserProfile from "../containers/UserProfile/UserProfile";
import StockManagement from "../containers/StockManagement/StockManagement";
import UserManagement from "../containers/UserManagement/UserManagement";

//Function will generate a different collection of acceptable routes dependent on users access level
export function getRoutes(userRole: string) {
  //By default, if attempting to access a route that hasnt been mapped, user should be returned to login
  let notFoundRoute = "/";
  //Outline default acceptable routes
  let allowedRoutes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "stock-item/:id",
      element: <StockItem />,
    },
    {
      path: "stock-list",
      element: <StockList />,
    },
  ];

  //If the user is logged in, in any capacity add the user-profile route as acceptable, and redirect them to home rather than login in case of accessing unacceptable route
  if (userRole === "standard" || userRole === "admin") {
    notFoundRoute = "/";
    allowedRoutes = allowedRoutes.concat([
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
    ]);
  } else {
    allowedRoutes = allowedRoutes.concat([
      {
        path: "user-profile",
        element: <Login />,
      },
      {
        path: "checkout",
        element: <Login />,
      },
      {
        path: "cart",
        element: <Login />,
      },
    ]);
  }

  //User logged in as admin, also open up management pages as acceptable routes
  if (userRole === "admin") {
    allowedRoutes = allowedRoutes.concat([
      {
        path: "stock-management",
        element: <StockManagement />,
      },
      {
        path: "user-management",
        element: <UserManagement />,
      },
    ]);
  }

  //Only allow user to access login or signup pages if they are NOT currently signed in
  if (userRole !== "admin" && userRole !== "standard") {
    allowedRoutes = allowedRoutes.concat([
      {
        path: "login",
        element: <Login />,
      },
    ]);
  }

  //Put together formatted routerOptions using prebuilt variables and return
  const routerOptions = [
    {
      path: "/",
      element: <Layout />,
      errorElement: <h1>Error 404: Page Not Found</h1>,
      children: allowedRoutes,
    },
    {
      path: "/*",
      element: <Navigate to={notFoundRoute} />,
    },
  ];
  return {
    routerOptions,
    routesStringArr: allowedRoutes.map((route) => route.path),
  };
}
