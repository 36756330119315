import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router";
import "./Home.css";
import React from "react";

//Declare styled text field using custom colour and styling functionality
const StyledButton = styled(Button)({
  backgroundColor: "#95A6A3",
  color: "black",
});

const Home = () => {
  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  return (
    <>
      {/*Create a grid containing the road bike cards and images*/}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        spacing={2}
        padding={2}
        paddingBottom={0}
      >
        {/* Road Bike Text Card*/}
        <Grid item xs={12} sm={4} className="reactiveGrid">
          <Card
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Creates selectable area over full text that will redirect if clicked */}
            <CardActionArea
              onClick={() => redirectToUrl("/stock-list?typeOptions=1")}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {/* Information about this stock collection should be shown below */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {"Road Bikes"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {
                    "Explore our collection of road bikes that can take you on an adventure by road, from the lowest valleys to the highest mountains."
                  }
                </Typography>
              </CardContent>
            </CardActionArea>

            {/* Creates button that will redirect if clicked */}
            <CardActions>
              <StyledButton
                size="small"
                color="primary"
                onClick={() => redirectToUrl("/stock-list?typeOptions=1")}
              >
                View Collection &gt;&gt;&gt;
              </StyledButton>
            </CardActions>
          </Card>
        </Grid>

        {/* Road Bike Image Card*/}
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: "100%" }}>
            <CardMedia
              component="img"
              image={process.env.PUBLIC_URL + "/images/roadBikesBanner.png"}
              alt={"Image of Road Bikes"}
              sx={{ height: "100%" }}
            />
          </Card>
        </Grid>
      </Grid>

      {/*Create a grid containing the e-bike cards and images*/}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        spacing={2}
        padding={2}
        paddingBottom={0}
      >
        {/* E Bike Text Card*/}
        <Grid item xs={12} sm={4} className="inverseReactiveGrid">
          <Card
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Creates selectable area over full text that will redirect if clicked */}
            <CardActionArea
              onClick={() => redirectToUrl("/stock-list?typeOptions=2")}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {/* Information about this stock collection should be shown below */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {"E-Bikes"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {
                    "View our range of E-Bikes. These can take you on a variety of terrain and provide some assistance for the steepest of hills."
                  }
                </Typography>
              </CardContent>
            </CardActionArea>

            {/* Creates button that will redirect if clicked */}
            <CardActions>
              <StyledButton
                size="small"
                color="primary"
                onClick={() => redirectToUrl("/stock-list?typeOptions=2")}
              >
                View Collection &gt;&gt;&gt;
              </StyledButton>
            </CardActions>
          </Card>
        </Grid>

        {/* E Bike Image Card*/}
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: "100%" }}>
            <CardMedia
              component="img"
              image={process.env.PUBLIC_URL + "/images/eBikesBanner.png"}
              alt={"Image of E Bikes"}
              sx={{ height: "100%" }}
            />
          </Card>
        </Grid>
      </Grid>

      {/*Create a grid containing the mountain bike cards and images*/}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        spacing={2}
        padding={2}
        paddingBottom={0}
      >
        {/* Mountain Bike Text Card*/}
        <Grid item xs={12} sm={4} className="reactiveGrid">
          <Card
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Creates selectable area over full text that will redirect if clicked */}
            <CardActionArea
              onClick={() => redirectToUrl("/stock-list?typeOptions=3")}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {/* Information about this stock collection should be shown below */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {"Mountain Bikes"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {
                    "Want to explore further off the beaten track? Take a look at our mountain bikes which will help you in viewing the road less travelled."
                  }
                </Typography>
              </CardContent>
            </CardActionArea>

            {/* Creates button that will redirect if clicked */}
            <CardActions>
              <StyledButton
                size="small"
                color="primary"
                onClick={() => redirectToUrl("/stock-list?typeOptions=3")}
              >
                View Collection &gt;&gt;&gt;
              </StyledButton>
            </CardActions>
          </Card>
        </Grid>

        {/* Mountain Bike Image Card*/}
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: "100%" }}>
            <CardMedia
              component="img"
              image={process.env.PUBLIC_URL + "/images/mountainBikesBanner.png"}
              alt={"Image of Mountain Bikes"}
              sx={{ height: "100%" }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
