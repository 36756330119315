import React, { useState } from "react";
import { CartStateInterface } from "../../App";
import { CartContext } from "../../contexts/CartContext/CartContext";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useNavigate, useSearchParams } from "react-router-dom";
import SingleChoiceDropdown from "../../components/shared/SingleChoiceDropdown/SingleChoiceDropdown";
import {
  getAllShippingOptions,
  processCheckout,
} from "../../api/checkout/checkout";

// Create currency formatter using en-GB
const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

const Checkout = () => {
  //load page parameters
  const [shippingParams] = useSearchParams();

  //Function will return the current shippingOptionID
  const getCurrentShippingID = () => {
    return shippingParams.get("shippingOption") !== null
      ? Number(shippingParams.get("shippingOption"))
      : shippingOptionDropwdown[0]?.id;
  };

  //Load cart state from cart context
  const {
    cartState,
    setCartState,
  }: { cartState?: CartStateInterface; setCartState?: any } =
    React.useContext(CartContext);

  //Declares the shipping option dropdown options
  const [shippingOptionDropwdown, setShippingOptionDropdown] = React.useState<
    { id: number; description: string }[]
  >([]);

  //Error flag react hook is declared, this hook signals whether there has been an error during page loading
  const [errorFlag, setErrorFlag] = React.useState<any | undefined>(undefined);

  //Control for dialog boxes
  const [dialogBoxOpen, setDialogBoxOpen] = React.useState("close");

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  //Try to load the shipping options
  React.useEffect(() => {
    getAllShippingOptions()
      //If request goes ok, then map response to proper format for shipping options dropdown
      .then((response) => {
        const mappedDropdownOptions = response.map((element: any) => {
          return {
            id: element.id,
            description:
              element.description + " @ " + formatter.format(element.cost),
          };
        });
        //Set state appropriately
        setShippingOptionDropdown(mappedDropdownOptions);
      })
      //If something goes wrong then set error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, []);

  //Declaring all form states
  const [formFirstName, setFormFirstName] = React.useState("");
  const [formLastName, setFormLastName] = React.useState("");
  const [formCompanyName, setFormCompanyName] = React.useState("");
  const [formPhoneNumber, setFormPhoneNumber] = React.useState("");
  const [formSAddressLineOne, setFormSAddressLineOne] = React.useState("");
  const [formSAddressLineTwo, setFormSAddressLineTwo] = React.useState("");
  const [formSAddressLineThree, setFormSAddressLineThree] = React.useState("");
  const [formSPostcode, setFormSPostcode] = React.useState("");
  const [formCardholdersName, setFormCardholdersName] = React.useState("");
  const [formCardNumber, setFormCardNumber] = React.useState("");
  const [formExpiryDate, setFormExpiryDate] = React.useState("");
  const [formCVC, setFormCVC] = React.useState("");
  const [formPAddresLine1, setFormPAddresLine1] = React.useState("");
  const [formPAddressLine2, setFormPAddressLine2] = React.useState("");
  const [formPAddressLine3, setFormPAddressLine3] = React.useState("");
  const [formPPostcode, setFormPPostcode] = React.useState("");

  //Function to be executed when a user places their order
  const placeOrderHandler = async () => {
    //Check that all required fields have been provided
    if (
      formFirstName &&
      formLastName &&
      formPhoneNumber &&
      formSAddressLineOne &&
      formSAddressLineThree &&
      formSPostcode &&
      formCardholdersName &&
      formCardNumber &&
      formExpiryDate &&
      formCVC &&
      formPAddresLine1 &&
      formPAddressLine3 &&
      formPPostcode
    ) {
      //Pass all necessary details to the process checkout function
      const response: any = await processCheckout(
        formFirstName,
        formLastName,
        formCompanyName,
        formPhoneNumber,
        formSAddressLineOne,
        formSAddressLineTwo,
        formSAddressLineThree,
        formSPostcode,
        formCardholdersName,
        formCardNumber,
        formExpiryDate,
        formCVC,
        formPAddresLine1,
        formPAddressLine2,
        formPAddressLine3,
        formPPostcode,
        getCurrentShippingID(),
        cartState?.cartItems ?? []
      );
      //If succesful response then wipe cart and local storage and show succesful popup box
      if (response?.status === 201) {
        setCartState({
          cartItems: [],
          totalCost: 0,
          loadedFromStorage: true,
        });
        localStorage.removeItem("BOBS_BIKES_CART");
        setDialogBoxOpen("success");
        //If not succesful response then show error
      } else {
        setDialogBoxOpen("error");
      }
    } else {
      //If not all fields have been provided then show error
      setDialogBoxOpen("error");
    }
  };

  //If an error was found then immediately just return the contents of the error handler function
  if (errorFlag !== undefined) {
    return errorHandler();
  }

  //Return layout for Checkout screen
  return (
    <div style={{ marginTop: "7px" }}>
      {cartState?.cartItems.length !== 0 ?? undefined ? (
        /*Create a grid to display the various parts of the checkout screen */
        <Grid container spacing={2} padding={2}>
          {/* Shipping details card */}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                {/* Shipping Details Header */}
                <Typography variant="h5" color="black" textAlign={"center"}>
                  {"Shipping Details"}
                </Typography>
                <Grid container spacing={2} padding={2}>
                  {/* Shipping Deteails Left Grid Column */}
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="First Name"
                        variant="outlined"
                        color="primary"
                        value={formFirstName}
                        onChange={(e) => setFormFirstName(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Last Name"
                        variant="outlined"
                        color="primary"
                        value={formLastName}
                        onChange={(e) => setFormLastName(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        color="primary"
                        value={formCompanyName}
                        onChange={(e) => setFormCompanyName(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Phone Number"
                        variant="outlined"
                        color="primary"
                        value={formPhoneNumber}
                        onChange={(e) => setFormPhoneNumber(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {/* Shipping Options Right Hand Column*/}
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Addres Line 1"
                        variant="outlined"
                        color="primary"
                        value={formSAddressLineOne}
                        onChange={(e) => setFormSAddressLineOne(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        label="Address Line 2"
                        variant="outlined"
                        color="primary"
                        value={formSAddressLineTwo}
                        onChange={(e) => setFormSAddressLineTwo(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Address Line 3"
                        variant="outlined"
                        color="primary"
                        value={formSAddressLineThree}
                        onChange={(e) =>
                          setFormSAddressLineThree(e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Postcode"
                        variant="outlined"
                        color="primary"
                        value={formSPostcode}
                        onChange={(e) => setFormSPostcode(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Payment Details Card*/}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                {/* Payment Details Header */}
                <Typography variant="h5" color="black" textAlign={"center"}>
                  {"Payment Details"}
                </Typography>
                {/* Payment Details Left Hand Column*/}
                <Grid container spacing={2} padding={2}>
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Cardholderes Name"
                        variant="outlined"
                        color="primary"
                        value={formCardholdersName}
                        onChange={(e) => setFormCardholdersName(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Card Number"
                        variant="outlined"
                        color="primary"
                        value={formCardNumber}
                        onChange={(e) => setFormCardNumber(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Expiry Date (MM/YY)"
                        variant="outlined"
                        color="primary"
                        value={formExpiryDate}
                        onChange={(e) => setFormExpiryDate(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="CVC"
                        variant="outlined"
                        color="primary"
                        value={formCVC}
                        onChange={(e) => setFormCVC(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {/* Payment Details Right Hand Column */}
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Address Line 1"
                        variant="outlined"
                        color="primary"
                        value={formPAddresLine1}
                        onChange={(e) => setFormPAddresLine1(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        label="Address Line 2"
                        variant="outlined"
                        color="primary"
                        value={formPAddressLine2}
                        onChange={(e) => setFormPAddressLine2(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Address Line 3"
                        variant="outlined"
                        color="primary"
                        value={formPAddressLine3}
                        onChange={(e) => setFormPAddressLine3(e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <TextField
                        required
                        label="Postcode"
                        variant="outlined"
                        color="primary"
                        value={formPPostcode}
                        onChange={(e) => setFormPPostcode(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Checkout Button and Shipping Option Grid Item */}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            {/* Button should be part of a card */}
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Checkout button and shipping option should be shown here*/}
              <CardActions
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                <SingleChoiceDropdown
                  potentialOptions={shippingOptionDropwdown}
                  existingIDs={getCurrentShippingID()}
                  defaultURL={"/checkout?shippingOption="}
                  labelText={"Shipping Option"}
                />

                {/* Button will handle order placement through calling placeOrderHandler function */}
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => placeOrderHandler()}
                  startIcon={<ShoppingCartCheckoutIcon />}
                >
                  Place Order
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        /* If no items were found in cart, page will just show that cart is empty */
        <Grid container spacing={2} padding={2}>
          {/* Simple Grid Item */}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign={"center"}
                >
                  {"No Items in Cart"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {/* Success Dialog Box*/}
      <Dialog
        open={dialogBoxOpen === "success"}
        onClose={() => setDialogBoxOpen("close")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Order Placed Succesfully"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogBoxOpen("close");
              redirectToUrl("/");
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog Box*/}
      <Dialog
        open={dialogBoxOpen === "error"}
        onClose={() => setDialogBoxOpen("close")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Order Failed"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unfortunately there was an issue creating your order. Please double
            check your details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogBoxOpen("close")}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Checkout;
