import { IconButton, Typography } from "@mui/material";
import React from "react";
import { getStockQty } from "../../../../api/stock/stock";
import {
  CartContext,
  CartContextInterface,
} from "../../../../contexts/CartContext/CartContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

//Declares the props that a given CartItem will need
interface StockAdjustProps {
  stockID: number;
  colourID: number;
  sizeID: number;
  price: number;
  quantity: { liveQuantity: any; setLiveQuantity(quantity: number): any };
}

//Error handler, this is the output that will be shown if something goes wrong
function errorHandler() {
  return <h1>Something went wrong while processing this page</h1>;
}

//Function that generates JSX for Stock Adjustment widget
export default function StockAdjust(props: StockAdjustProps) {
  const { stockID, colourID, sizeID, quantity, price } = props;

  //Declare relevant react states
  const [thisVariantQuantity, setThisVariantQuantity] = React.useState<any>();
  const [errorFlag, setErrorFlag] = React.useState<any | undefined>(undefined);

  //Load cart state and addToCart function from CartContext
  const { cartState, addToCart }: CartContextInterface =
    React.useContext(CartContext);

  //Try to load the quantity data and assign to relevant state
  React.useEffect(() => {
    getStockQty(stockID, sizeID, colourID)
      //If request goes ok, then assign response to stock data react hook
      .then((response) => {
        setThisVariantQuantity(response);
      })
      //If something goes wrong then setStockData error attribute to equal the error that was caught
      .catch((error) => setErrorFlag({ error }));
  }, [stockID, sizeID, colourID, cartState]);

  if (errorFlag !== undefined) {
    return errorHandler();
  }

  //Logic to be executed when a user tries to adjust the quantity of an item in stock
  const addToCartHandler = (changeAmount: number) => {
    //Attempt to ajust one of the chosen items in cart
    if (
      addToCart({
        stockID: stockID,
        colourID: colourID,
        sizeID: sizeID,
        quantity: changeAmount,
        maxQuantityForVariant: thisVariantQuantity,
        unitPrice: price,
        //If succesful, update local states
      })
    ) {
      //If change succesful then update the "live quantity" to reflect cartState change
      quantity.setLiveQuantity(quantity.liveQuantity + changeAmount);
    }
  };

  return (
    <>
      {/* Subtract quantity button should only be visible if there is at least one item in stock*/}
      <IconButton
        onClick={() => addToCartHandler(-1)}
        disabled={quantity.liveQuantity - 1 < 0}
        color="primary"
      >
        <RemoveCircleOutlineIcon />
      </IconButton>

      {/* Display currenty qty to user*/}
      <Typography variant="body2" color="text.secondary">
        {"Qty:" + quantity.liveQuantity}
      </Typography>

      {/* Increase stock quantity button should only be selectable if there is enough stock to fulfill order*/}
      <IconButton
        onClick={() => addToCartHandler(1)}
        disabled={quantity.liveQuantity + 1 > thisVariantQuantity}
        color="primary"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </>
  );
}
