export default function Footer() {
  return (
    //Declares footer with fixed position and bottom 0 so it sticks to bottom of screen
    <footer
      style={{
        backgroundColor: "#585858",
        position: "fixed",
        bottom: 0,
        width: "100%",
        padding: "5px",
      }}
    >
      {/*Outlines footer text */}
      <h5 style={{ color: "white", margin: "0px" }}>
        Site Designed By Elliot Fenwick - 30224310
      </h5>
    </footer>
  );
}
