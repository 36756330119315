import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./Navbar.css";
import { Badge, TextField, styled } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndividualCartItem, SectionsContext } from "../../../App";
import {
  CartContext,
  CartContextInterface,
} from "../../../contexts/CartContext/CartContext";

//Declare the style that all icons should follow
const whiteIconStyle = {
  color: "white",
};

//create styled versions of each icon that the navbar will use
const StyledSearchIcon = styled(SearchIcon)(whiteIconStyle);
const StyledAccountBoxIcon = styled(AccountBoxIcon)(whiteIconStyle);
const StyledShoppingCartIcon = styled(ShoppingCartIcon)(whiteIconStyle);

//Declare styled text field using custom colour and styling functionality
const StyledTextField = styled(TextField)({
  backgroundColor: "white",
  label: { color: "black" },
  "#search-bar-text-field-label": { color: "black" },
});

export default function Navbar() {
  //Load cart state from cart context
  const { cartState }: CartContextInterface = React.useContext(CartContext);

  //Declare a react hook that will track the current value of the search box
  const [mySearchValue, setMySearchValue] = useState("");

  //Load the sections from app context
  const sections = React.useContext(SectionsContext);

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  return (
    <React.Fragment>
      {/* 
      Toolbar is declared as a flex wrap container that justifies content. 
      This will wrap the contents and center items if there is any free space to do so.
      */}
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "#585858",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {/*The image should be of flex type 1 which will fill the space available to it, image is also a hyperlink to home page */}
        <div style={{ flex: "1", maxWidth: "100%", marginRight: "5px" }}>
          <button style={{ border: 0, padding: 0, backgroundColor: "#585858" }}>
            <img
              src={process.env.PUBLIC_URL + "/images/bobsBikesLogoCropped.png"}
              alt="Bobs Bikes Logo"
              className="reactiveImage"
              onClick={() => redirectToUrl("/")}
            />
          </button>
        </div>
        {/*Search box is part of reactiveSearchTools class which will shift it to the end 
        of the flex box row when screen size becomes too small to fit it. Also puts a key 
        capture event on search box to search if the enter key is hit */}
        <StyledTextField
          id="search-bar-text-field"
          label="Search Term"
          variant="filled"
          size="small"
          InputLabelProps={{ shrink: true }}
          focused={false}
          value={mySearchValue}
          onChange={(e) => setMySearchValue(e.target.value)}
          className="reactiveSearchTools"
          margin="dense"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              redirectToUrl(`/stock-list?searchValue=${mySearchValue}`);
            }
          }}
        />

        {/* Search Icon is also part of reactiveSearchTools class so that it moves to end of row with 
          Search text field (which will put both onto a new row). onClick it will run the redirect function
        */}
        <IconButton
          onClick={() =>
            redirectToUrl(`/stock-list?searchValue=${mySearchValue}`)
          }
          className="reactiveSearchTools"
        >
          <StyledSearchIcon />
        </IconButton>

        {/* Show the user if there are items in the cart */}
        <Badge
          badgeContent={
            cartState?.cartItems.reduce(
              (count: number, element: IndividualCartItem) =>
                count + element.quantity,
              0
            ) ?? 0
          }
          color="primary"
          overlap="circular"
          variant="dot"
        >
          <IconButton onClick={() => redirectToUrl("/cart")}>
            <StyledShoppingCartIcon />
          </IconButton>
        </Badge>
        <IconButton onClick={() => redirectToUrl("/user-profile")}>
          <StyledAccountBoxIcon />
        </IconButton>
        {/*This div fills up the end of the row with as much space as is left
        If any item is therefore placed after this div it will be forced onto a new row */}
        <div style={{ flexBasis: "100%" }}></div>
      </Toolbar>

      {/*This toolbar contains the link buttons to the various application pages */}
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          justifyContent: "space-between",
          overflowX: "auto",
          backgroundColor: "#95A6A3",
        }}
      >
        {/* Each section should have a button created for it */}
        {sections.map((section) => (
          <Button
            variant="text"
            key={section.title}
            onClick={() => redirectToUrl(section.url)}
            sx={{ color: "white", flexShrink: 0 }}
          >
            {section.title}
          </Button>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}
