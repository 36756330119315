import { httpDelete, httpPost } from "../utils/api.utils";

//Function will take in username and password and submit them, return an error response if something goes wrong
async function loginAttempt(username: string, password: string) {
  //Define URL for login attempt
  let url = "authentication/login";

  //Try to login
  try {
    const response = await httpPost<any>(url, { email: username, password });
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    return error;
  }
}

//Function will take in username and password as parameters, call the signup endpoint and return an error response type if something goes wrong
async function signupAttempt(username: string, password: string) {
  //Define URL
  let url = "authentication/signup";

  //Try to submit data
  try {
    const response = await httpPost<any>(url, { email: username, password });
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    return error;
  }
}

//Function to delete the currently logged in users account
async function deleteAccount() {
  //Define URL
  let url = "authentication/deleteaccount";

  //Try to submit data
  try {
    const response = await httpDelete(url);
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    return error;
  }
}

//Function to update the currently logged in users password
async function updatePassword(oldPassword: string, newPassword: string) {
  //Define URL
  let url = "authentication/changepassword";

  //Try to submit data
  try {
    const response = await httpPost<any>(url, { oldPassword, newPassword });
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    return error;
  }
}

//Function to change a specified users role
async function changeRole(emailToUpdate: string, newRole: string) {
  //Define URL
  let url = "authentication/updateuserrole";

  //Try to submit data
  try {
    const response = await httpPost<any>(url, { emailToUpdate, newRole });
    return response;
    //Return error response if something goes wrong
  } catch (error) {
    return error;
  }
}

//Export functionality
export {
  loginAttempt,
  signupAttempt,
  deleteAccount,
  updatePassword,
  changeRole,
};
