import { createTheme } from "@mui/material/styles";
import { teal } from "@mui/material/colors";

// Default theme for app, should have grey background and use fonts declared
//Also declares a primary colour of teal to match general theme of site
const theme = createTheme({
  palette: {
    background: {
      default: "#E3E3E3",
    },
    primary: teal,
  },
  typography: {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  },
});
export default theme;
