import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { changeRole } from "../../api/userAccounts/userAccounts";

const UserManagement = () => {
  //Setup necessary states / hooks
  const [updateRoleNotification, setUpdateRoleNotification] = React.useState({
    type: "error",
    description: "",
  });
  const [updateUser, setUpdateUser] = React.useState("");

  //Function to be executed when you try to update the user
  const handleUpdate = async (newRole: string) => {
    //Check that an email has been entered
    if (updateUser !== "") {
      //If it has then try to change the role
      const response: any = await changeRole(updateUser, newRole);
      //On a succesful response
      if (response.status === 201) {
        //Show a success message
        setUpdateRoleNotification({
          type: "success",
          description:
            "User " +
            updateUser +
            " succesfully updated to " +
            newRole.toUpperCase() +
            " role.",
        });
        //if status is not 201 something has gone wrong, show an error instead
      } else {
        setUpdateRoleNotification({
          type: "error",
          description:
            "Update failed, please make sure a valid email address was provided (you cannot update your own permissions).",
        });
      }
    } else {
      //if no email provided, show an error
      setUpdateRoleNotification({
        type: "error",
        description: "Please provide an email address",
      });
    }
  };
  return (
    <>
      {/* Place page inside a grid */}
      <Grid container spacing={2} sx={{ padding: "5px" }}>
        {/* Update User Permissions Card */}
        <Grid item xs={12} sx={{ marginTop: "5px" }}>
          <Card sx={{ width: "100%" }}>
            <CardContent
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              {/* Textbox for user to enter email that they are trying to update */}
              <TextField
                label="Account to update"
                variant="filled"
                color="primary"
                value={updateUser}
                onChange={(e: any) => setUpdateUser(e.target.value)}
                fullWidth={true}
              />

              {/* If there is a notification to show*/}
              {updateRoleNotification.description !== "" ? (
                /* Display the detail using an appropriate colour for the type of notification */
                <Typography
                  variant="body2"
                  color={
                    updateRoleNotification.type === "error"
                      ? "red"
                      : "DarkSeaGreen"
                  }
                  sx={{ width: "100%", height: "100%", textAlign: "right" }}
                >
                  {updateRoleNotification.description}
                </Typography>
              ) : null}
            </CardContent>

            {/* Declare button area*/}
            <CardActions
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "right",
              }}
            >
              {/* Add button labels */}
              <Typography
                variant="body2"
                sx={{
                  width: "100%",
                  height: "100%",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                Set Permissions For User To
              </Typography>

              {/* Declare set standard and set admin buttons. 
                Each operates the same but passes a different value 
                to the handleUpdate function*/}
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => handleUpdate("standard")}
                sx={{ textAlign: "center" }}
              >
                Standard
              </Button>

              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => handleUpdate("admin")}
                sx={{ textAlign: "center" }}
              >
                Admin
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserManagement;
