import React from "react";
import { CartStateInterface } from "../../App";
import { CartContext } from "../../contexts/CartContext/CartContext";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useNavigate } from "react-router-dom";
import CartItem from "./components/CartItem/CartItem";

// Create currency formatter using en-GB
const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const Cart = () => {
  //Load cart state from cart context
  const { cartState }: { cartState?: CartStateInterface; setCartState?: any } =
    React.useContext(CartContext);

  const navigate = useNavigate();

  //Setup redirect function that this component can use to navigate to another part of the app
  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  //Return layout for Cart screen
  return (
    <div style={{ marginTop: "7px" }}>
      {cartState?.cartItems.length !== 0 ?? undefined ? (
        /*Create a grid to display each cart item and the checkout button */
        <Grid container spacing={2} padding={2}>
          {/* Array of rows, one for each cart item*/}
          {cartState?.cartItems.map((element, index) => (
            <CartItem
              stockID={element.stockID}
              colourID={element.colourID}
              sizeID={element.sizeID}
              quantity={element.quantity}
              key={"indCartItem" + index}
            />
          ))}

          {/* Checkout Button Grid Item */}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            {/* Button should be part of a card */}
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                {/* Total cost shown here */}
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign={"right"}
                >
                  {"Total Order Amount: " +
                    formatter.format(cartState?.totalCost ?? 0)}
                </Typography>
              </CardContent>
              {/* Checkout button should be shown here*/}
              <CardActions
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                {/* Button will redirect user to checkout */}
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => redirectToUrl("/checkout")}
                  startIcon={<ShoppingCartCheckoutIcon />}
                >
                  Checkout
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        /* If no items were found, page will just show that cart is empty */
        <Grid container spacing={2} padding={2}>
          {/* Simple Grid Item */}
          <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
            <Card
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "right",
                }}
              >
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign={"center"}
                >
                  {"No Items in Cart"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Cart;
