import { Toolbar } from "@mui/material";
import GenericFilter from "../../../components/shared/GenericFilter/GenericFilter";

//Declares the props that a given ListItem will need
interface FiltersBarProps {
  typeOptions: ReadonlyArray<{
    id: number;
    description: string;
  }>;
  colourOptions: ReadonlyArray<{
    id: number;
    description: string;
  }>;
  sizeOptions: ReadonlyArray<{
    id: number;
    description: string;
  }>;
  typeOptionExistingIDs: ReadonlyArray<number>;
  colourOptionExistingIDs: ReadonlyArray<number>;
  sizeOptionExistingIDs: ReadonlyArray<number>;
  searchValue?: string;
}

export default function FiltersBar(props: FiltersBarProps) {
  //Assign variables by extracting them from props
  const {
    typeOptions,
    colourOptions,
    sizeOptions,
    typeOptionExistingIDs,
    colourOptionExistingIDs,
    sizeOptionExistingIDs,
    searchValue,
  } = props;

  //Calculate the various URL components that can be stitched together in case of a redirect
  const baseURL =
    searchValue !== undefined
      ? "/stock-list?searchValue=" + searchValue + "&"
      : "/stock-list?";
  const typeQuery = "typeOptions=" + typeOptionExistingIDs.join(",");
  const sizeQuery = "sizeOptions=" + sizeOptionExistingIDs.join(",");
  const colourQuery = "colourOptions=" + colourOptionExistingIDs.join(",");

  return (
    <>
      {/* Filters should be in a toolbar at the top of the screen, various CSS is applied here to help with formatting */}
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
          flex: 1,
          maxWidth: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "right",
          margin: "5px",
        }}
      >
        {/*The toolbar should contain a filter dropdown with the options as passed to fitlerBar */}
        <GenericFilter
          potentialOptions={typeOptions}
          existingIDs={typeOptionExistingIDs}
          defaultURL={baseURL + sizeQuery + "&" + colourQuery + "&typeOptions="}
          labelText="Filter by Type"
        />

        <GenericFilter
          potentialOptions={sizeOptions}
          existingIDs={sizeOptionExistingIDs}
          defaultURL={baseURL + typeQuery + "&" + colourQuery + "&sizeOptions="}
          labelText="Filter by Size"
        />

        <GenericFilter
          potentialOptions={colourOptions}
          existingIDs={colourOptionExistingIDs}
          defaultURL={baseURL + sizeQuery + "&" + typeQuery + "&colourOptions="}
          labelText="Filter by Colour"
        />
      </Toolbar>
    </>
  );
}
