import React from "react";
import { UserStateInterface } from "../../App";
import { UserContext } from "../../contexts/UserContext/UserContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { removeAuthHeader } from "../../api/utils/api.utils";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  deleteAccount,
  updatePassword,
} from "../../api/userAccounts/userAccounts";

//User profile container function
const UserProfile = () => {
  //Load user state from user context
  const {
    userState,
    setUserState,
  }: { userState?: UserStateInterface; setUserState?: any } =
    React.useContext(UserContext);

  //Establish necessary react states / hooks for this screen
  const [deleteError, setDeleteError] = React.useState("");
  const [updatePasswordNotification, setUpdatePasswordNotification] =
    React.useState({ type: "error", description: "" });
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);

  //Callable functions that will open and close dialogue box by interacting with its state hook
  const handleOpenConfirm = () => {
    setOpenDialogueBox(true);
  };

  const handleCloseConfirm = () => {
    //If user cancels a delete then it should remove any existing error messages
    setDeleteError("");
    setOpenDialogueBox(false);
  };

  //Declare function that will carry out logout process
  const handleLogout = () => {
    //Token is removed from local storage, from axios request headers and the user state reset
    localStorage.removeItem("BOBS_BIKES_JWT");
    removeAuthHeader();
    setUserState({ userRole: "loggedOut" });
    return undefined;
  };

  //Declare function that will handle account deletion functionality
  const handleDeletion = async () => {
    //Hide the confirmation box
    handleCloseConfirm();
    const response: any = await deleteAccount();
    //If deletion was succesful
    if (response?.status === 200) {
      //Then log out user from front end as well
      handleLogout();
    } else {
      //Otherwise, throw error to user
      setDeleteError("Something went wrong trying to delete this account");
    }
  };

  //Function that will handle update password functionality
  const handleUpdatePassword = async () => {
    //First check that user has provided all necessary details
    if (
      oldPassword.length >= 1 &&
      newPassword.length >= 1 &&
      newPasswordConfirm.length >= 1 &&
      newPassword === newPasswordConfirm
    ) {
      //Then attempt to update password using this information
      const response: any = await updatePassword(oldPassword, newPassword);
      //If a response of 201 is received then
      if (response?.status === 201) {
        //Show appropriate success message
        setUpdatePasswordNotification({
          type: "success",
          description: "Password updated succesfully",
        });
        //Otherwise display error
      } else {
        setUpdatePasswordNotification({
          type: "error",
          description:
            "Something went wrong updating your password. Please check your details and try again.",
        });
      }
      //If details were missing or not matching then show error
    } else {
      setUpdatePasswordNotification({
        type: "error",
        description:
          "The details provided either did not match or were missing, please check and try again",
      });
    }
  };

  //Return layout for user profile page
  return (
    <>
      {/* Place page inside a grid */}
      <Grid container spacing={2} sx={{ padding: "5px" }}>
        {/* Title and logout card */}
        <Grid item xs={12} sx={{ marginTop: "5px" }}>
          <Card>
            <CardContent>
              {/* Show users name and role*/}
              <Typography gutterBottom variant="h5" component="div">
                {"Account Management For: " + userState?.userEmail ?? ""}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {"Users Role: " + userState?.userRole}
              </Typography>
            </CardContent>

            {/* Logout button, should execute handleLogout functon on click*/}
            <CardActions sx={{ height: "100%" }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => handleLogout()}
                endIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Update password item*/}
        <Grid item xs={12} sm={6}>
          {/* Put contents in a card, make it execute the updatePasword function on enter key*/}
          <Card
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdatePassword();
              }
            }}
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              {/* Show user three text boxes, each will update its associated state variable when typed in */}
              <Typography gutterBottom variant="h5" component="div">
                {"Change Password"}
              </Typography>
              {/* Same as above */}
              <TextField
                label="Old Password"
                variant="filled"
                type="password"
                InputLabelProps={{ shrink: true }}
                focused={false}
                value={oldPassword}
                onChange={(e: any) => setOldPassword(e.target.value)}
                margin="dense"
                fullWidth={true}
              />
              {/* Same as above */}
              <TextField
                label="New Password"
                variant="filled"
                type="password"
                InputLabelProps={{ shrink: true }}
                focused={false}
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
                margin="dense"
                fullWidth={true}
              />
              {/* Same as above */}
              <TextField
                label="Confirm New Password"
                variant="filled"
                type="password"
                InputLabelProps={{ shrink: true }}
                focused={false}
                value={newPasswordConfirm}
                onChange={(e: any) => setNewPasswordConfirm(e.target.value)}
                margin="dense"
                fullWidth={true}
              />
            </CardContent>

            {/* If there is a notification to show*/}
            {updatePasswordNotification.description !== "" ? (
              /* Display the detail using an appropriate colour for the type of notification */
              <Typography
                variant="body2"
                color={
                  updatePasswordNotification.type === "error" ? "red" : "green"
                }
                sx={{ width: "100%", height: "100%", textAlign: "center" }}
              >
                {updatePasswordNotification.description}
              </Typography>
            ) : null}

            {/* Declare button for user to click */}
            <CardActions
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => handleUpdatePassword()}
                endIcon={<SyncLockIcon />}
              >
                Update Password
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Delete account grid item */}
        <Grid item xs={12} sm={6}>
          {/* Again, contents a put in a card for visual continuit */}
          <Card
            sx={{
              width: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Only show error message if bad details have been entered */}
            {deleteError !== "" ? (
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                {/* Further information then shown using typography components */}
                <Typography variant="body2" color={"red"}>
                  {deleteError}
                </Typography>
              </CardContent>
            ) : null}

            {/* Delete account button should be shown here*/}
            <CardActions
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              {/* Button to trigger dialog box to open */}
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={handleOpenConfirm}
                startIcon={<DeleteIcon />}
              >
                DELETE ACCOUNT
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog box to make sure that a user wants to delete their account before following through with it */}
      <Dialog
        open={openDialogueBox}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* Title in larger writing to be clear */}
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete your account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is irreversible, do not confirm unless you are sure and
            understand the consequences.
          </DialogContentText>
        </DialogContent>
        {/* Two buttons are placed at the bottom of the box*/}
        <DialogActions>
          {/* Cancel will close the window and remove any existing error from the screen */}
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={handleCloseConfirm}
            startIcon={<CancelIcon />}
            autoFocus
          >
            Cancel
          </Button>

          {/* Confirm will carry out the account deletion logic */}
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleDeletion}
            startIcon={<DeleteIcon />}
          >
            Yes I'm Sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserProfile;
